.search {
  height: 32px;
  width: 200px;
  border: none;
  border-bottom: solid 1px lightblue;
  transition: all 250ms ease-in;
  padding-left: 15px;
}

.search:focus {
  outline: none;
  border-bottom: solid 1.5px blue;
  transition: all 250ms ease-in;
}

.search-button {
  background: transparent;
  border: none;
  outline: none;
  margin-left: -33px;
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}
