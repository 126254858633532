.login-button {
  width: 238px;
  height: 68px;
  flex-shrink: 0;
  background: #0363f9;
  color: #fff;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
}

.login-button:hover {
  background: #0d203d;
}

.page {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.header-text {
  color: #0d203d;
  text-align: center;
  font-family: Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}

.subtext {
  color: #0d203d;
  text-align: center;
  font-family: Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 80%;
}

.image {
  max-width: 100%;
  width: 70%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../public/images/obrazok.png");
}

.login-main {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  height: 45%;
  width: 75%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-left: 50px;
  margin-top: 30px;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button-container {
  display: flex;
  height: 50%;
  flex-direction: column;
}

.login-button-text {
  margin-left: 10px;
}

.login-button-img {
  margin-right: 15px;
}

.forgot-password {
  margin-top: 3px;
  color: #1a98ff;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password:hover {
  color: #177ed2;
  text-decoration: underline;
}
