.empty-text {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.empty-image {
  width: 278px;
}

.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  gap: 25px;
}
