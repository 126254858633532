.app-tile-small {
  width: 316px;
  height: 79px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.app-tile-small:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle {
  width: 28px;
  height: 28px;
  background-color: #0363f9;
  display: flex;
  border-radius: 100%;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
}

.small-tile-title {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.person {
  height: 20px;
}

.tile-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  gap: 5px;
}

.tile-arrow {
  padding-right: 20px;
}

.tile-version {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}
