.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup {
  display: flex;
  flex-direction: column;
  height: 309px;
  width: 631px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  padding: 30px;
  justify-content: space-between;
  z-index: 2;
}

.popup-title {
  color: #0d203d;
  font-family: Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}

.popup-text {
  color: #0d203d;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.popup-button:hover {
  background: #0d203d;
}

.popup-input-text {
  color: #201f1e;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-dismiss {
  height: 24px;
  cursor: pointer;
}

.popup-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.popup-versions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.popup-text-bold {
  font-weight: bold;
}

.popup-checkbox {
  height: 20px;
  width: 20px;
}

.popup-button-download {
  display: flex;
  justify-content: flex-end;
  border: none;
}

.button-style:disabled {
  background-color: gray;
  cursor: default;
}
