.app-tile-big {
  width: 316px;
  height: 443px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.app-tile-big:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

.button-style {
  width: 163px;
  height: 51px;
  background: #0363f9;
  color: #fff;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.button-style:hover {
  background: #0d203d;
}

.tile-header {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
}

.version {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.main-text {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 250px;
  height: 150px;
  overflow: auto;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.padding-bottom {
  padding-bottom: 15px;
}

.version-date {
  display: flex;
  flex-direction: column;
  align-items: center;
}
