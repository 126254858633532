.error-message {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid rgba(208, 27, 36, 0.77);
  width: 75%;
  margin-top: 25px;
}
.error-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
}
