.app {
  display: flex;
  flex-direction: column;
  background: #f7fbfe;
  height: 100vh;
  overflow: hidden;
}

.tile-grid {
  display: grid;
  gap: 35px;
  grid-template-columns: repeat(auto-fill, minmax(316px, 1fr));
  grid-column-end: auto;
}

.title {
  color: #0d203d;
  font-family: Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  overflow-y: scroll;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-16-16 {
  height: 16px;
  width: 16px;
}

.app-verison {
  margin-left: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}
