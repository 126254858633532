.header {
  min-height: 83px;
  background: #0d203d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.user {
  color: #fff;
  text-align: right;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 25px;
}

.options {
  display: flex;
  align-items: center;
  width: 120px;
  height: 28px;
  position: absolute;
  right: 0;
  transform: translateX(calc(50% - 53px));
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  gap: 10px;
  padding: 5px;
  justify-content: center;
}

.options::before {
  content: "";
  position: inherit;
  top: -6px; /* Adjust position as needed */
  right: 6px;
  transform: translateX(-50%);
  border-width: 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent #fff; /* Triangle color */
}

.options:hover {
  background-color: rgb(214, 214, 214);
}

.user-dropdown {
  position: relative;
  cursor: pointer;
}

.sign-out-text {
  color: #0d203d;
  text-align: right;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.arrow-down {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff; /* Triangle color */
  margin-left: 5px;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sign-out-img {
  height: 16px;
}
