.progressbar {
  position: absolute;
  z-index: 50;
  right: 0;
  bottom: 0;
  width: 614px;
  height: 600pxs;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding-bottom: 10px;
}

.label {
  display: flex;
  justify-content: space-between;
  margin: 0px 15px 5px 15px;
}

.download-text {
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.download-title {
  color: #000;
  font-weight: 600;
}

.file-size {
  color: #959595;
  font-weight: 200;
}
